@import '../../bower_components/bootstrap/dist/css/bootstrap';

/*******************************************************************************
 * Colors
 * ------
 * These colors are currently based on the solarized theme
 *
 * @ref: http://ethanschoonover.com/solarized
 ******************************************************************************/

$base00:    #FFFFFF;
$base01:    #EBEEF0;
$base02:    #000000;
$base03:    #D96545;    
$base0:     #000000;
$base1:     #EEEEEE;
$base2:     #0078BD;
$base3:     #D96545;
$base4:    #449d44;

$font01: 'Open Sans', sans-serif;
$font02: 'Merriweather', serif;


/*******************************************************************************
 * Tag Defaults
 * ------------
 * Default style for tags
 ******************************************************************************/

body {
  background-color: $base01;
  color: $base0;
}

html, body {
  height: 100%;
}

a {
  cursor: pointer;
}

section, article, header, footer {
  display: block;
}

h1,h2,h3,h4,h5,h6 {
  color: $base0;
}

p {
  color: $base0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hide {
  display: none;
}

.table > thead > tr > th, 
.table > tbody > tr > th, 
.table > tfoot > tr > th, 
.table > thead > tr > td, 
.table > tbody > tr > td, 
.table > tfoot > tr > td {
  padding: 0.75em;
}

.toggle {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .toggle-btn {
    outline: 0;
    display: block;
    width: 160px;
    height: 33.33px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .toggle-btn:after {
    left: 50%;
  }
}

/* ===============================================================
  Toggle
================================================================ */
.toggle-slide {
  + .toggle-btn {
      overflow: hidden;
      backface-visibility: hidden;
      transition: all .2s ease;
      font-family: sans-serif;
      background: #888;
      margin-bottom: 0;
      border-radius: 4px;
      box-shadow: 0 4px 8px rgba(10, 10, 10, 0.1);

      &:after,
      &:before {
        display: inline-block;
        transition: all .2s ease;
        width: 100%;
        text-align: center;
        position: absolute;
        line-height: 33.34px;
        font-weight: bold;
        color: #fff;
      }

      &:after {
        left: 100%;
        content: attr(data-toggle-on);
      }

      &:before {
        left: 0;
        content: attr(data-toggle-off);
      }

      &:active {
        background: #888;
        &:before {
          left: -10%;
        }
      }
  }

  &:checked + .toggle-btn {
    background: #86d993;
    &:before {
      left: -100%;
    }

    &:after {
      left: 0;
    }

    &:active:after {
      left: 10%;
    }
  }
}

/* ===============================================================
  Checkbox
================================================================ */
input[type="checkbox"].tpx-checkbox {
  display: none;
}

.checkbox-container {
  label {
    position: relative;
    padding-right: 40px;
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    
    &::before {
      right: 0;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      background-color: rgb(235,235,235);
    }
    
    &::after {
      right: 3.5px;
      height: 1.35em;
      content: '\f00c';
      font-family: 'FontAwesome';
      font-size: 1.4em;
      transition: left .25s, background-color .25s;
      color: white;
      opacity: 0.3;
    }
    
    &:hover {
      cursor: pointer;
    }
  }
  
  input[type="checkbox"]:checked {
    & + label::before {
      background-color: $base4;
      opacity: 1;
    }
    
    & + label::after {
      opacity: 1;
    }
  }
  
}

@font-face {
  font-family: "Open Sans";
  src: url('./../fonts/OpenSans-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Merriweather";
  src: url('./../fonts/Merriweather-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Merriweather";
  font-weight: 700;
  src: url('./../fonts/Merriweather-Bold.ttf') format("truetype");
}
@font-face {
  font-family: "Merriweather";
  font-weight: 900;
  font-style: italic;
  src: url('./../fonts/Merriweather-BlackItalic.ttf') format("truetype");
}

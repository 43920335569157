@import './main.scss';
@import './fonts.scss';

#app-root {
  padding: 0 2em;
}

.ui-main-section {
  margin: 2em 0;
  padding: 1.5em;
  background-color: $base00;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
  .section-header-text {
    margin: 0.5em 0 1em 0;
  }
}

#verification-screen {
  padding: 64px 0;
  
  .verifying-text,
  .verification-error {
    text-align: center;
  }
  
  .spinning-wheel {
    text-align: center;
    margin-top: 64px;
  }
}

#printer-section {
  
  .section-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;


    #printer-ctrl {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      
      > * {
        margin-left: 2em;
      }
    }
  } 
}


#pagination {
  text-align: center;
}


#order-table {
  
  .column-header {
    display:flex;
    align-items: center;
    justify-content: space-between;

    .column-header-name {
      font-size: 1.25em;
      line-height: 40px;
    }

    .column-sort-control {
      .ctrl-btn:hover {
        cursor: pointer;
      }

      .glyphicon.inactive {
        color: $base1
      }
      .glyphicon.active {
        color: $base3
      }
    }
  }
  
  tr {
    .action-btn {
      background-color: $base01;
    }
    
    .action-btn:hover {
      background-color: $base00;
      color: $base2;
    }
  }
}

.dropdown {
  .dropdown-btn-text {
    margin-right: 1em;
  }
  
  .dropdown-btn-icon {
    color: $base3;
    top: 2px;
    margin-right: 0.5em;
  }
}

#inventory-section {
  #inventory-list {
    & > li {
      margin: 2em 0;
      
      .location-header {
        margin-bottom: 1.5em;
        display: flex;
        align-items: center;

        & > h3 {
          margin: 0;
          flex: 1 1 auto;
          max-width: 20em;
          transition: color linear 0.25s;
        }
        
        & > a {
          color: black;
          font-size: 1.75em;
          transition: color linear 0.25s;
        }
        
        &:hover {
          cursor: pointer;
          
          & > h3,
          & > a {
            color: $base3;
          }
        }
      }
      
      & button.save-inventory {
        width: 10em;
        padding: 0.75em 1em;
        background-color: $base00;
        color: $base3;
        border: solid 1px $base3;
        transition: all linear 0.25s;
        
        &:hover {
          background-color: $base3;
          color: $base00;
        }
      }
      
    }
  }
  
  .inventory-by-location {
    display: flex;
    flex-wrap: wrap;
    transition: all linear 0.25s;
    
    .checkbox-container > label {
      min-width: 16em;
      margin-bottom: 1.5em;
      margin-right: 4em;
    }
  }
  
  .btn-notification {
    display: flex;
    align-items: baseline;
    
    & > .notification {
      flex: 1 1 auto;
      margin-left: 2em;
      font-weight: 700;
      
      &.success {
        color: $base4;
      }
      
      &.error {
        color: $base3;
      }
    }
  }
  
  .modal-inventory-list {
    padding: 0 1em;
    
    & > li {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 0.5em 0;
      
      & .inventory-item-available {
        color: $base4;
        
        &.unavailable {
          color: red;
        }
      }
    }
  }
  
}

/* Extra wide screen */
@media only screen and (min-width: 1201px) {

}

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {

}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {
  
  
}